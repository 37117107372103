import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { Criteo } from '~/components/Criteo/Criteo';
import SEO from '~/components/seo';
import { DeriveHeaderClasses } from '~/components/Navigation';
import styled, { css } from 'styled-components';
import { gsap, Linear } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { MotionPathPlugin } from 'gsap/dist/MotionPathPlugin';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import MailingListPopup from '~/components/JoinMailingList/MailingListPopup';

// Hooks
// import { getWidth } from '../hooks/use-current-width'

import { getImage } from '../helpers';
import { breakpoint } from '../constants/Breakpoint';

import Hero from '../components/.base/hero';
import {
  HeadingLarge,
  Heading,
  HeadingXL,
  BodyText,
  LargeStencil,
  SolidStencil,
} from '~/components/.base/headings';
import { RedButton, WhiteButton } from '~/components/.base/buttons';
import { Container, BackgroundDiv, CenteredContainer } from '~/components/.base/containers';
import {} from '~/components/.base/containers';
import { Road1, Road2, Road3, Road4 } from '../components/.base/roads';

import DotTrailIcon from '~/images/icons/checkout/dot-trail.svg';
import { WoodenBorder } from '../components/.base/border';

const Banner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    max-width: 800px;
    margin: 0 auto 3rem;
    text-align: center;
  }
`;
const SectionGrid = styled.div`
  @media ${breakpoint.lg} {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 10%;
    grid-row-gap: 30px;
  }
  @media ${breakpoint.xl} {
    grid-column-gap: 19%;
  }
`;
const Section = styled.section`
  position: relative;
  padding: ${({ p }) => (p ? p : '30px 0')};
  @media ${breakpoint.lg} {
    padding: ${({ lgP }) => (lgP ? lgP : '60px 0')};
  }
  ${BodyText} {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: -0.2px;
    margin-bottom: 40px;
  }
  ${HeadingXL} {
    font-size: 114px;
    @media ${breakpoint.lg} {
      font-size: 144px;
    }
  }
  button {
    margin: 0 auto 30px;
  }
`;
const SectionHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  /* margin-top: 80px; */
  margin-bottom: 20px;
  align-items: flex-end;
  @media ${breakpoint.lg} {
    grid-template-columns: auto minmax(0, 1fr);
    margin-top: 0;
    margin-bottom: 50px;
  }
`;
const About = ({ data, location }) => {
  const [windowLoaded, setWindowLoaded] = useState(false);
  const [domLoaded, setDomLoaded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);

  const {
    aboutPageImages,
    bgImages,
    contentfulAboutPage: {
      titleHtml,
      subtitleText,
      theBeginningTextLeft,
      theBeginningTextRight,
      theBeginningImage,
      theBeginningTextQuote,
      truckLaunchBanner,
      truckLaunchTextLeft,
      truckLaunchTextRight,
      truckLaunchTextQuote,
      // truckLaunchImageLeft,
      truckLaunchImageRight,
      sharkTankTextLeft,
      sharkTankTextRight,
      sharkTankTextQuote,
      // sharkTankImageLeft,
      sharkTankImageRight,
      californiaTextLeft,
      californiaTextRight,
      californiaImageLeft,
      // californiaImageRight,
      nowBanner,
      nowTextLeft,
      nowTextRight,
      nowTextQuote,
      nowImageLeft,
      nowImageRight,
      thankYouTextQuote,
    },
  } = data;

  gsap.registerPlugin(DrawSVGPlugin, MotionPathPlugin, ScrollTrigger);

  useEffect(() => {
    // timeoutId for debounce mechanism
    const getWidth = () =>
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWindowWidth(getWidth()), 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    document.addEventListener('DOMContentLoaded', function (event) {
      setDomLoaded(true);
    });
    window.addEventListener('load', event => {
      // Page is fully loaded
      setWindowLoaded(true);
    });
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    // setDomLoaded(true)
    let scrollTriggers = [];
    setTimeout(function () {
      document.querySelectorAll('.timelineWrapper').forEach(timelineWrapper => {
        let dottedLine = timelineWrapper.querySelector('.timeline__line--dotted'),
          completedLine = timelineWrapper.querySelector('.timeline__line--completed'),
          pointer = timelineWrapper.querySelector('.timeline__pointer'),
          reverse = timelineWrapper.getAttribute('data-path-reverse') === 'true';

        // Setting start postion of lobster in path's starting point
        gsap.set(pointer, {
          motionPath: {
            path: dottedLine,
            align: dottedLine,
            start: reverse ? 1 : 0,
            alignOrigin: [0.5, 0.5],
          },
        });

        let tl = gsap
          .timeline()
          .to(
            pointer,
            {
              ease: Linear.easeNone,
              duration: 1,
              motionPath: {
                start: reverse ? 1 : 0,
                end: reverse ? 0 : 1,
                path: dottedLine,
                align: dottedLine,
                autoRotate: false,
                alignOrigin: [0.5, 0.5],
              },
            },
            0
          )
          .fromTo(
            completedLine,
            {
              drawSVG: reverse ? '100% 100%' : '0% 100%',
            },
            {
              ease: Linear.easeNone,
              duration: 1,
              drawSVG: reverse ? '0% 100%' : '100% 100%',
            },
            0
          );

        const st = ScrollTrigger.create({
          ease: Linear.easeNone,
          trigger: dottedLine,
          scrub: 0,
          start: 'top center',
          end: 'bottom center',
          onUpdate: ({ progress }) => {
            timelineWrapper.querySelectorAll('.timeline__dot--dotted').forEach(item => {
              let position = item.getAttribute('data-position');
              if (progress >= position) {
                item.style.visibility = 'hidden';
                item.style.opacity = '0';
              } else {
                item.style.visibility = 'visible';
                item.style.opacity = '1';
              }
            });
            timelineWrapper.querySelectorAll('.timeline__dot--completed').forEach(item => {
              let position = item.getAttribute('data-position');
              if (progress >= position) {
                item.style.visibility = 'visible';
                item.style.opacity = '1';
              } else {
                item.style.visibility = 'hidden';
                item.style.opacity = '0';
              }
            });
          },
          animation: tl,
        });

        scrollTriggers.push(st);
      });

      let tlShark = gsap.timeline().to(`.section--sharkTankBanner ${SolidStencil}`, {
        x: '0',
      });
      let stShark = ScrollTrigger.create({
        trigger: '.section--sharkTankBanner',
        start: 'center-=25% center',
        end: 'center center-=75%',
        scrub: 0.5,
        animation: tlShark,
      });
      scrollTriggers.push(stShark);

      let tlThank = gsap.timeline().to(`.section--thankYou ${LargeStencil}`, {
        x: '0',
      });
      let stThank = ScrollTrigger.create({
        trigger: '.section--thankYou',
        start: 'center center',
        end: 'center center-=75%',
        scrub: 0.5,
        animation: tlThank,
      });
      scrollTriggers.push(stThank);
    }, 250);

    // Specify how to clean up after this effect:
    return () => {
      // all garbage cleanup all goes in a single useEffect for when component leaves
      if (scrollTriggers) {
        scrollTriggers.map(st => {
          st.kill();
          return true;
        });
      }
    };
  }, [domLoaded, windowLoaded, windowWidth]);
  return (
    <>
      <MailingListPopup />
      <SEO title="About Us" />
      <DeriveHeaderClasses location={location} />
      <Criteo />
      <div
        className="about"
        css={css`
          overflow-x: hidden;
          img {
            width: 100%;
            height: auto;
          }
          .timeline__dot--completed {
            visibility: hidden;
            opacity: 0;
          }
          .timeline {
            @media ${breakpoint.maxMd} {
              display: none;
            }
          }
        `}
      >
        <Hero
          size="full"
          imageFluid={getImage(aboutPageImages.edges, 'about_hero')}
          wrapperClass="hero"
          alt=""
          noOverlay
        >
          <Container className="cont">
            <CenteredContainer className="hero-details">
              <HeadingLarge>
                <span
                  dangerouslySetInnerHTML={{
                    __html: titleHtml,
                  }}
                ></span>
              </HeadingLarge>
              <BodyText>{subtitleText ? subtitleText.subtitleText : null}</BodyText>
              <div
                css={css`
                  position: absolute;
                  bottom: 30px;
                  p {
                    color: #fff;
                  }
                  svg {
                    transform: rotate(90deg);
                    stroke: #fff;
                    margin-top: 20px;
                  }
                  @media screen and (max-width: 950px) {
                    display: none;
                  }
                `}
              >
                <WhiteButton as="span" className="scroll-for-more-text">
                  Scroll for more
                </WhiteButton>
                <DotTrailIcon />
              </div>
            </CenteredContainer>
          </Container>
        </Hero>
        {/* Hero */}
        <Section className="timelineWrapper" data-path-reverse="true" p="60px 0 0" lgP="150px 0 0">
          <BackgroundDiv bottom="-118px" left="23.5%" z="-1" w="53%">
            <Image fluid={getImage(bgImages.edges, 'blurred_smudge_4@3x')} alt="" />
          </BackgroundDiv>
          <Road1
            styling={`
                        position: absolute;
                        left: 50%;
                        bottom: auto;
                        transform: translateX(-50%);
                        overflow: visible;
                        height: 75%;
                        z-index: 3;
                    `}
          />
          <Container>
            <SectionGrid>
              <div>
                <SectionHeader
                  css={css`
                    margin-top: 0;
                  `}
                >
                  <Heading
                    as="p"
                    css={css`
                      @media ${breakpoint.lg} {
                        text-align: right;
                      }
                    `}
                  >
                    The <br />
                    <span className="red">beginning</span>
                  </Heading>
                  <HeadingXL as="h2">
                    <span className="red">2011</span>
                  </HeadingXL>
                </SectionHeader>
                <BodyText textIndent="5rem">{theBeginningTextLeft.theBeginningTextLeft}</BodyText>
              </div>
              <div
                css={css`
                  width: 345px;
                  align-self: center;
                  .gatsby-image-wrapper {
                    width: 100%;
                  }
                `}
              >
                <Image fluid={theBeginningImage.fluid} alt="" />
              </div>
              <HeadingLarge
                as="p"
                className="tr"
                css={css`
                  margin-top: 50px;
                  margin-bottom: 40px;
                `}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: theBeginningTextQuote,
                  }}
                ></span>
              </HeadingLarge>
              <BodyText textIndent="5rem">{theBeginningTextRight.theBeginningTextRight}</BodyText>
            </SectionGrid>
          </Container>
          <div
            css={css`
              max-width: 570px;
              margin-left: auto;
              margin-bottom: -15px;
            `}
          >
            <Image fluid={getImage(aboutPageImages.edges, 'about-us-our-story-cropped')} alt="" />
          </div>
        </Section>
        {/* 2011 */}
        <Section as="div" p="0" lgP="0">
          <Banner
            css={css`
              button${RedButton} {
                margin-top: 20px;
              }
            `}
          >
            <div
              css={css`
                position: relative;
                width: 100%;
                overflow: hidden;
                .gatsby-image-wrapper {
                  width: 100%;
                }
              `}
            >
              <WoodenBorder top="0" zIndex="1" />
              <WoodenBorder top="100%" zIndex="1" />
              <Image fluid={truckLaunchBanner.fluid} alt="truck launch" />
            </div>
            <RedButton
              as={Link}
              to="/locations"
              css={css`
                position: relative;
                z-index: 5;
              `}
            >
              Find a foodtruck nearby
            </RedButton>
          </Banner>
        </Section>
        {/* Banner */}
        <Section className="timelineWrapper" data-path-reverse="true">
          <Road2
            styling={`
                        position: absolute;
                        left: 50%;
                        top: -15%;
                        transform: translateX(-50%);
                        overflow: visible;
                        height: 100%;
                        z-index: 2;
                    `}
          />
          <Container>
            <SectionGrid>
              <div
                css={css`
                  width: 365px;
                  max-width: 100%;
                  margin-left: auto;
                  margin-bottom: 90px;
                  @media ${breakpoint.lg} {
                    grid-area: 1 / 1 / 3 / 2;
                    margin-bottom: 0;
                  }
                `}
              >
                <Image
                  fluid={getImage(aboutPageImages.edges, 'about_launch')}
                  alt="Food truck launch"
                />
              </div>
              <div
                css={css`
                  button${RedButton} {
                    transform: translateY(-50%);
                  }
                `}
              >
                <SectionHeader>
                  <HeadingXL as="h2">
                    <span className="red">2012</span>
                  </HeadingXL>
                  <Heading as="p">
                    The
                    <span className="red">
                      {' '}
                      truck <br />
                      launch
                    </span>
                  </Heading>
                </SectionHeader>
                <BodyText
                  textIndent="5rem"
                  css={css`
                    margin-bottom: 0;
                  `}
                >
                  {truckLaunchTextRight.truckLaunchTextRight}
                </BodyText>
              </div>
              <div
                css={css`
                  @media ${breakpoint.lg} {
                    grid-area: 2 / 2 / 5 / 3;
                  }
                  ${RedButton} {
                    transform: translateY(-50%);
                  }
                `}
              >
                <div>
                  <Image fluid={truckLaunchImageRight.fluid} alt="lobster sandwich" />
                </div>
                <RedButton to="/shop">Order Online</RedButton>
              </div>
              <BodyText
                textIndent="5rem"
                css={css`
                  @media ${breakpoint.lg} {
                    grid-area: 3 / 1 / 5 / 2;
                  }
                `}
              >
                {truckLaunchTextLeft.truckLaunchTextLeft}
              </BodyText>
            </SectionGrid>
          </Container>
        </Section>
        {/* 2012 */}
        <Section>
          <BackgroundDiv
            z="-1"
            left="14%"
            top="0"
            w="65%"
            css={css`
              transform: translateY(-12%);
            `}
          >
            <Image fluid={getImage(bgImages.edges, 'blurred_smudge_2@3x')} alt="background" />
          </BackgroundDiv>
          <BackgroundDiv
            top="-22.91vw"
            z="-2"
            w="100%"
            css={css`
              height: 79vw;
              img {
                height: 100%;
              }
            `}
          >
            <WoodenBorder top="0" zIndex="1" />
            <WoodenBorder top="79vw" zIndex="1" />
            <Image fluid={getImage(bgImages.edges, 'full_blue_bg')} alt="background" />
          </BackgroundDiv>
          <Container>
            <Banner>
              <HeadingLarge as="h2">
                <span
                  dangerouslySetInnerHTML={{
                    __html: truckLaunchTextQuote,
                  }}
                ></span>
              </HeadingLarge>
            </Banner>
          </Container>
        </Section>
        {/* Shark Tank */}
        <Section
          as="div"
          className="section--sharkTankBanner"
          css={css`
            overflow: hidden;
          `}
        >
          <SolidStencil
            as="p"
            css={css`
              right: 0;
              top: 50%;
              transform: translate(calc(3755px - 100vw), -50%);
              @media ${breakpoint.maxSm} {
                display: none;
              }
            `}
          >
            Shark Tank
          </SolidStencil>
          <Banner>
            <div
              css={css`
                width: 65%;
                max-width: 620px;
              `}
            >
              <Image
                fluid={getImage(aboutPageImages.edges, 'about_barb')}
                alt="Barb and the fellas"
              />
            </div>
          </Banner>
        </Section>
        {/* Banner */}
        <div
          className="timelineWrapper"
          data-path-reverse="true"
          css={css`
            position: relative;
          `}
        >
          <Road3
            styling={`
                        position: absolute;
                        left: 50%;
                        top: 0;
                        transform: translateX(-50%);
                        height: 100%;
                        overflow: visible;
                    `}
          />
          <Section>
            <BackgroundDiv
              top="32vw"
              z="-2"
              w="100%"
              css={css`
                height: 60vw;
                .gatsby-image-wrapper {
                  height: 100%;
                }
              `}
            >
              <WoodenBorder top="0" zIndex="1" />
              <WoodenBorder top="60vw" zIndex="1" />
              <Image fluid={getImage(bgImages.edges, 'full_blue_bg')} alt="background" />
            </BackgroundDiv>
            <Container>
              <SectionGrid>
                <div>
                  <SectionHeader>
                    <HeadingXL as="h2">
                      <span className="red">2016</span>
                    </HeadingXL>
                    <Heading as="p">
                      Shark <br />
                      <span className="red"> Tank</span>
                    </Heading>
                  </SectionHeader>
                  <BodyText
                    textIndent="5rem"
                    css={css`
                      margin-bottom: 40px;
                    `}
                  >
                    {sharkTankTextLeft.sharkTankTextLeft}
                  </BodyText>
                  <div
                    css={css`
                      margin-bottom: 40px;
                    `}
                  >
                    <Image
                      fluid={getImage(aboutPageImages.edges, 'about_sharktank2')}
                      alt="Cousins with the Sharks"
                    />
                  </div>
                </div>
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    .gatsby-image-wrapper {
                      width: 100%;
                      margin-bottom: 30px;
                    }
                  `}
                >
                  <Image fluid={sharkTankImageRight.fluid} alt="the cousins on shark tank" />
                  <BodyText
                    textIndent="5rem"
                    css={css`
                      margin-bottom: 25px;
                      order: -1;
                      @media ${breakpoint.lg} {
                        order: initial;
                      }
                    `}
                  >
                    {sharkTankTextRight.sharkTankTextRight}
                  </BodyText>
                  <HeadingLarge
                    as="p"
                    css={css`
                      max-width: 400px;
                    `}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: sharkTankTextQuote,
                      }}
                    ></span>
                  </HeadingLarge>
                </div>
              </SectionGrid>
            </Container>
          </Section>
          {/* 2016 */}
          <Section>
            <Container>
              <SectionGrid
                css={css`
                  button${RedButton} {
                    transform: translateY(-50%);
                  }
                `}
              >
                <SectionHeader>
                  <HeadingXL as="h2">
                    <span className="red">2017</span>
                  </HeadingXL>
                  <Heading as="p">
                    California <br />
                    <span className="red"> & Beyond</span>
                  </Heading>
                </SectionHeader>
                <div
                  css={css`
                    @media ${breakpoint.lg} {
                      grid-area: 1 / 1 / 3 / 2;
                    }
                    .gatsby-image-wrapper {
                      width: 100%;
                      margin-bottom: 30px;
                    }
                  `}
                >
                  <Image fluid={californiaImageLeft.fluid} alt="cousins maine restaurant" />
                  <Link to="/locations">
                    <RedButton>Find a restaurant nearby</RedButton>
                  </Link>
                  <BodyText textIndent="5rem">{californiaTextLeft.californiaTextLeft}</BodyText>
                </div>
                <div>
                  <div
                    css={css`
                      width: 365px;
                      max-width: 100%;
                      ${WhiteButton} {
                        margin-top: 15px;
                      }
                    `}
                  >
                    <Image
                      fluid={getImage(aboutPageImages.edges, 'about_lobsterboat')}
                      alt="Lobster Boat"
                    />
                    <WhiteButton to="/collections/lobster">ORDER FRESH LOBSTER</WhiteButton>
                  </div>
                  <BodyText textIndent="5rem">{californiaTextRight.californiaTextRight}</BodyText>
                </div>
              </SectionGrid>
            </Container>
          </Section>
          {/* 2017 */}
        </div>
        <Section as="div">
          <Banner>
            <div
              css={css`
                width: 100%;
              `}
            >
              <WoodenBorder top="0" zIndex="1" />
              <WoodenBorder top="100%" zIndex="1" />
              <Image fluid={nowBanner.fluid} alt="Now" />
            </div>
          </Banner>
        </Section>
        {/* Banner */}
        <Section>
          <BackgroundDiv
            z="-1"
            right="0%"
            top="0"
            w="36.68%"
            css={css`
              transform: translateX(32.84%);
            `}
          >
            <Image fluid={getImage(bgImages.edges, 'blurred_smudge@3x')} alt="background" />
          </BackgroundDiv>
          <Container>
            <Banner>
              <HeadingLarge as="h2">
                <span
                  dangerouslySetInnerHTML={{
                    __html: nowTextQuote,
                  }}
                ></span>
              </HeadingLarge>
            </Banner>
          </Container>
        </Section>
        {/* Family Recipes */}
        <Section className="timelineWrapper" data-path-reverse="true">
          <Road4
            styling={`
                        position: absolute;
                        left: 50%;
                        top: 0;
                        transform: translateX(-50%);
                        overflow: visible;
                        height: 100%;
                    `}
          />
          <BackgroundDiv top="75%" z="-1" w="100%">
            <WoodenBorder top="0" zIndex="1" />
            <WoodenBorder top="100%" zIndex="1" />
            <Image fluid={getImage(bgImages.edges, 'full_blue_bg')} alt="background" />
          </BackgroundDiv>
          <Container>
            <SectionGrid>
              <div>
                <SectionHeader>
                  <HeadingXL as="h2">
                    <span className="red">Now</span>
                  </HeadingXL>
                  <Heading as="p">
                    Way, way <br />
                    <span className="red"> beyond</span>
                  </Heading>
                </SectionHeader>
                <BodyText textIndent="5rem">{nowTextLeft.nowTextLeft}</BodyText>
                <div
                  css={css`
                    max-width: 405px;
                    ${RedButton} {
                      margin-top: 30px;
                    }
                    .gatsby-image-wrapper {
                      width: 100%;
                    }
                  `}
                >
                  <Image fluid={nowImageLeft.fluid} alt="about tots" />
                  <Link to="/shop">
                    <RedButton
                      css={css`
                        margin: 30px auto;
                      `}
                    >
                      Buy Online
                    </RedButton>
                  </Link>
                </div>
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  .gatsby-image-wrapper {
                    width: 100%;
                  }
                `}
              >
                <Image
                  fluid={nowImageRight.fluid}
                  alt="Lobster sandwich"
                  css={css`
                    order: 13;
                    margin-bottom: 35px;
                    @media ${breakpoint.lg} {
                      order: initial;
                    }
                  `}
                />
                <BodyText textIndent="5rem">{nowTextRight.nowTextRight}</BodyText>
              </div>
            </SectionGrid>
          </Container>
        </Section>
        {/* Now */}
        <Section
          as="div"
          className="section--thankYou"
          css={css`
            overflow: hidden;
          `}
        >
          <LargeStencil
            as="p"
            css={css`
              right: 0;
              top: 50%;
              transform: translate(calc(2585px - 100vw), -50%);
            `}
          >
            THANK YOU
          </LargeStencil>
          <Container>
            <Banner>
              <div
                css={css`
                  width: 625px;
                  max-width: 100%;
                `}
              >
                <Image
                  fluid={getImage(aboutPageImages.edges, 'about_bros2')}
                  alt="Lobster sandwich"
                />
              </div>
            </Banner>
          </Container>
        </Section>
        {/* Thank You */}
        <Section p="30px 0 60px" lgP="60px 0 120px">
          <BackgroundDiv
            z="-1"
            left="0%"
            bottom="0"
            w="69.03%"
            css={css`
              transform: translateX(-26.3%);
            `}
          >
            <Image fluid={getImage(bgImages.edges, 'blurred_smudge_3@3x')} alt="background" />
          </BackgroundDiv>
          <Container>
            <Banner>
              <HeadingLarge as="h2">
                <span
                  dangerouslySetInnerHTML={{
                    __html: thankYouTextQuote,
                  }}
                ></span>
              </HeadingLarge>
              <RedButton to="/shop">Shop now</RedButton>
            </Banner>
          </Container>
        </Section>
        {/* Thankful */}
      </div>
    </>
  );
};

export const query = graphql`
  query {
    contentfulAboutPage(title: { eq: "About Page" }) {
      titleHtml
      subtitleText {
        subtitleText
      }
      theBeginningTextLeft {
        theBeginningTextLeft
      }
      theBeginningTextRight {
        theBeginningTextRight
      }
      theBeginningTextQuote
      theBeginningImage {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      truckLaunchBanner {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      truckLaunchTextLeft {
        truckLaunchTextLeft
      }
      truckLaunchTextRight {
        truckLaunchTextRight
      }
      truckLaunchTextQuote
      truckLaunchImageLeft {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      truckLaunchImageRight {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      sharkTankTextLeft {
        sharkTankTextLeft
      }
      sharkTankTextRight {
        sharkTankTextRight
      }
      sharkTankTextQuote
      sharkTankImageLeft {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      sharkTankImageRight {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      californiaTextLeft {
        californiaTextLeft
      }
      californiaTextRight {
        californiaTextRight
      }
      californiaImageLeft {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      californiaImageRight {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      nowBanner {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      nowTextLeft {
        nowTextLeft
      }
      nowTextRight {
        nowTextRight
      }
      nowTextQuote
      nowImageLeft {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      nowImageRight {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      thankYouTextQuote
    }
    aboutPageImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/images/about/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    bgImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/images/background/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 915, quality: 100) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default About;
